import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  success: null,
  error: null,
  reviews: [],
};

const businessReviewSlice = createSlice({
  name: 'businessReview',
  initialState,
  reducers: {
    fetchBusinessReviewsRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchBusinessReviewsSuccess: (state, action) => {
      state.isLoading = false;
      state.reviews = action.payload.reviews;
    },
    fetchBusinessReviewsFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    submitBusinessReviewRequest: (state) => {
      state.isLoading = true;
      state.success = null;
      state.error = null;
    },
    submitBusinessReviewSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    submitBusinessReviewFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    
    resetBusinessReviewState: (state) => {
      state.isLoading = false;
      state.success = null;
      state.error = null;
    },
  },
});

export const {
  fetchBusinessReviewsRequest,
  fetchBusinessReviewsSuccess,
  fetchBusinessReviewsFailure,
  submitBusinessReviewRequest,
  submitBusinessReviewSuccess,
  submitBusinessReviewFailure,
  resetBusinessReviewState,
} = businessReviewSlice.actions;

export default businessReviewSlice.reducer;
