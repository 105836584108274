import { call, put, takeLatest } from 'redux-saga/effects';
import { $host, $authHost } from '../axios';
import { bookServicesRequest, bookServicesSuccess, bookServicesFailure } from './bookServiceSlice';
import { clearCart, addPaymentData } from 'store/cart/cartSlice';
import { savePaymentIntentToSessionStorage } from 'utils/sessionStorage';

function* bookServicesSaga(action) {
  const { cartItems, payload, api, navigate, promo } = action.payload;
  const bookServiceData = {
    ...payload,
    ...(promo.discount && { promo_discount: promo.discount })
  }

  try {
    const response = yield call($authHost.post, 'booking/book-services', bookServiceData);

    if (response.data.code === 400) {
      api.error({
        description: response.data.description,
      });
    }

    const { order_id, payment_intent, ephemeral_key, amount, currency } = response.data.data;
    if (response.data.code === 200) {
      const paymentIntentData = {
        order_id,
        amount,
        currency,
        payment_intent,
        items: cartItems,
      };

      yield put(clearCart());
      savePaymentIntentToSessionStorage(paymentIntentData);
      yield put(addPaymentData(paymentIntentData));
      navigate('/pay-now', {
        state: {
          amount_paid: payload.amount_paid,
          total_charges: payload.total_charges,
          booking_id: order_id,
          promo,
        },
      });
    }
  } catch (error) {
    yield put(bookServicesFailure(error.response?.data?.message || 'Booking failed'));
  }
}

export default function* bookServicesRootSaga() {
  yield takeLatest(bookServicesRequest.type, bookServicesSaga);
}
