import { combineReducers } from 'redux';
import authReducer from './auth/authSlice';
import CustomizerReducer from './customizer/CustomizerSlice';
import SettingsReducer from './settings/settingsSlice';
import ProfileReducer from './profile/profileSlice';
import DashboardReducer from './dashboard/dashboardSlice';
import favoriteReducer from './favorite/favoriteSlice';
import bookingsReducer from './bookings/bookingsSlice';
import commonsReducer from './commons/commonsSlice';
import forgotpwdRducer from './forgotpwd/forgotpwdSlice';
import notificationReducer from './notification/notificationSlice';
import businessesReducer from './businesses/businessesSlice';
import orderBookingReducer from './orderBooking/orderBookingSlice';
import BookingReschedule from './bookingReschedule/bookingRescheduleSlice';
import serviceReviewSlice from './serviceReview/serviceReviewSlice';
import businessesReviewSlice from './businessesReview/businessesReviewSlice';
import specialistScheduleSlice from './serviceEssentials/serviceEssentialsSlice';
import cartSlice from './cart/cartSlice';
import bookServiceSlice from './bookService/bookServiceSlice';
import messagesSlice from './chat/chatSlice';
import promotionsSlice from './promotions/promotionsSlice';
// extras
import NotesReducer from './apps/notes/NotesSlice';
import ContactsReducer from './apps/contacts/ContactSlice';
import EmailReducer from './apps/email/EmailSlice';
import TicketReducer from './apps/ticket/TicketSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  customizer: CustomizerReducer,
  settings: SettingsReducer,
  profile: ProfileReducer,
  favorite: favoriteReducer,
  bookings: bookingsReducer,
  dashboard: DashboardReducer,
  forgotPassword: forgotpwdRducer,
  notification: notificationReducer,
  orderBooking: orderBookingReducer,
  businesses: businessesReducer,
  cartReducer: cartSlice,
  common: commonsReducer,
  serviceReview: serviceReviewSlice,
  businessesReview: businessesReviewSlice,
  specialistSchedule: specialistScheduleSlice,
  notesReducer: NotesReducer,
  contactsReducer: ContactsReducer,
  emailReducer: EmailReducer,
  ticketReducer: TicketReducer,
  chatReducer: messagesSlice,
  bookingReschedule: BookingReschedule,
  bookServiceReducer: bookServiceSlice,
  promotionsReducer: promotionsSlice,
});

export default rootReducer;
