import { takeLatest, put, call } from 'redux-saga/effects';
import { fetchBusinessReviewsRequest, fetchBusinessReviewsSuccess, fetchBusinessReviewsFailure, submitBusinessReviewRequest, submitBusinessReviewSuccess, submitBusinessReviewFailure } from './businessesReviewSlice';
import { $host, $authHost } from '../axios';

// Fetch Business Reviews Saga
function* fetchBusinessReviewsSaga(action) {
  try {
    const response = yield call($host.get, `/business-review/${action.payload}`);
    const { total_rating, business_reviews } = response.data.data;
    yield put(fetchBusinessReviewsSuccess({ totalRating: total_rating, reviews: business_reviews }));
  } catch (error) {
    yield put(fetchBusinessReviewsFailure(error.message));
  }
}

// Submit Business Review Saga
function* submitBusinessReviewSaga(action) {
  try {
    const response = yield call($authHost.post, '/business-review', action.payload);
    yield put(submitBusinessReviewSuccess(response.data.message));
  } catch (error) {
    yield put(submitBusinessReviewFailure(error.message));
  }
}

export default function* businessReviewSaga() {
  yield takeLatest(fetchBusinessReviewsRequest.type, fetchBusinessReviewsSaga);
  yield takeLatest(submitBusinessReviewRequest.type, submitBusinessReviewSaga);
}
