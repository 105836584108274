import { takeLatest, put, call } from 'redux-saga/effects';
import { $host, $authHost } from 'store/axios';
import {
  getChats,
  fetchChats,
  sendMsg,
  sendMsgFailure,
  fetchContacts,
  fetchContactsSuccess,
} from './chatSlice';

function* fetchChatsSaga(action) {
  const { sender_id, receiver_id, booking_id } = action.payload;

  let url;
  if (booking_id) {
    url = `/messages/${sender_id}/${receiver_id}/${booking_id}`;
  } else {
    url = `/messages/${sender_id}/${receiver_id}`;
  }

  try {
    const response = yield call($host.get, url);
    yield put(getChats(response.data.data.messages));
  } catch (err) {
    console.error(err);
    yield put(getChats([]));
  }
}

function* fetchContactsListSaga(action) {
  const { user_id } = action.payload;
  try {
    const response = yield call($host.get, `/messages/${user_id}`);
    yield put(fetchContactsSuccess(response.data.data.contacts));
  } catch (err) {
    console.error(err);
    yield put(fetchContactsSuccess([]));
  }
}

function* sendMessageSaga(action) {
  try {
    const { chatMsg } = action.payload;
    yield call($host.post, `/messages/send-message`, chatMsg);
    const payload = {
      sender_id: chatMsg.receiver_id,
      receiver_id: chatMsg.sender_id,
      booking_id: chatMsg.booking_id,
    };
    yield put(fetchChats(payload));
  } catch (err) {
    console.error(err);
    yield put(sendMsgFailure(err.message));
  }
}

export default function* chatSaga() {
  yield takeLatest(fetchChats.type, fetchChatsSaga);
  yield takeLatest(sendMsg.type, sendMessageSaga);
  yield takeLatest(fetchContacts.type, fetchContactsListSaga);
}
