import { createSlice } from '@reduxjs/toolkit';

const promotionsSlice = createSlice({
  name: 'promotions',
  initialState: {
    promotion: null,
    loading: false,
    error: null,
  },
  reducers: {
    fetchPromotionRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchPromotionSuccess(state, action) {
      state.loading = false;
      state.promotion = action.payload;
    },
    fetchPromotionFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updatePromotionUsageRequest(state) {
      state.loading = true;
      state.error = null;
    },
    updatePromotionUsageSuccess(state) {
      state.loading = false;
    },
    updatePromotionUsageFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    resetPromotionStates(state) {
      state.promotion = null;
      state.loading = false;
      state.error = null;
    },
  },
});

export const {
  fetchPromotionRequest,
  fetchPromotionSuccess,
  fetchPromotionFailure,
  updatePromotionUsageRequest,
  updatePromotionUsageSuccess,
  updatePromotionUsageFailure,
  resetPromotionStates
} = promotionsSlice.actions;

export default promotionsSlice.reducer;
