import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchPromotionRequest,
  fetchPromotionSuccess,
  fetchPromotionFailure,
  updatePromotionUsageRequest,
  updatePromotionUsageSuccess,
  updatePromotionUsageFailure,
} from './promotionsSlice';
import { $host, $authHost } from 'store/axios';

function* fetchPromotion(action) {
  try {
    const response = yield call($authHost.get, `/promotions/pomo/${action.payload}`);
    if (response.status === 200) {
      yield put(fetchPromotionSuccess(response.data.data));
    } else {
      yield put(fetchPromotionFailure(response.data.message));
    }
  } catch (error) {
    yield put(fetchPromotionFailure(error.response.data));
  }
}

function* updatePromotionUsage(action) {
  try {
    yield call($authHost.put, `/promotions/update-uses/${action.payload}`);
    yield put(updatePromotionUsageSuccess());
  } catch (error) {
    yield put(updatePromotionUsageFailure(error.response.data));
  }
}

// Watcher saga
export function* promotionsSaga() {
  yield takeLatest(fetchPromotionRequest.type, fetchPromotion);
  yield takeLatest(updatePromotionUsageRequest.type, updatePromotionUsage);
}
